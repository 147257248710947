import React from 'react';
export const Banner01DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title lu7rvudb5dc-editor_css',
    children:
      'https://bike.powersuit.cn/uploads/6c0xwhs7/admin/images/20240104/20240104212956c47780060.png',
  },
  content: {
    className: 'banner0-content lu8298kwou-editor_css',
    children: (
      <span>
        <span>
          <p>-- 南星泽兰 --</p>
        </span>
      </span>
    ),
  },
  button: {
    className: 'banner0-button lu8292828ad-editor_css',
    children: 'Learn More',
    type: 'default',
    href: '',
  },
};
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper lu828d8jayk-editor_css' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children:
      'https://bike.powersuit.cn/uploads/6c0xwhs7/admin/images/20240326/20240326110532c6cf85566.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>
                      企业<span>简介</span>
                    </p>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'lu7rxxudx0r-editor_css',
            },
          ],
        },
        subItem: [
          {
            name: 'sub0',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: 'Ant Design',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
          {
            name: 'sub1',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: 'Ant Design',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '企业级 UI 设计体系',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>品牌简介</p>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <p>产品简介</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>产品服务</p>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children:
      'https://bike.powersuit.cn/uploads/6c0xwhs7/admin/images/20240326/20240326110532c6cf85566.png',
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: 'Content3_0',
        children: '企业简介',
        className: 'menu-item',
      },
      {
        name: 'linkNav~lu81ycc7klc',
        to: 'Pricing0_0',
        children: '商城列表',
        className: 'menu-item',
      },
      {
        name: 'linkNav~lu81narudrt',
        to: 'Content5_0',
        children: '产品简介',
        className: 'menu-item',
      },
      {
        name: 'linkNav~lu81nct9u8r',
        to: 'Content5_1',
        children: '战略合作',
        className: 'menu-item',
      },
      {
        name: 'linkNav~lu82evtel4',
        to: 'Feature1_0',
        children: '联系我们',
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>企业简介</p>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content lu7ry981gp6-editor_css',
        children: (
          <span>
            <span>
              <p>陕西南星泽兰商贸有限公司</p>
            </span>
          </span>
        ),
      },
      {
        name: 'content~lu812ri3ru',
        className: 'lu812wtq17-editor_css',
        children: (
          <span>
            <span>
              <p>
                POWERSUIT是国内专业的电商服务平台，致力于应用数字技术的红利，为人们提供更便捷的购物以及更好的普惠生活服务。公司成立于2023年9月，总部位于西安，从大家熟悉的电商业务起步。目前POWERSUIT主要提供线上购物和点餐服务及新兴本地服务。
              </p>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>愿景使命</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>
                        <u>
                          应用数字技术红利，为人们提供更便捷的软件服务，以及更好的普惠生活服务
                        </u>
                      </p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <span>
                    <p>企业价值观</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <p>以客户为中心、专业进取、危机意思、诚信正直、团队精神</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>企业人才观</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <p>理性思维、最高标准、学习能力、主人翁精神、内心强大</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Teams00DataSource = {
  wrapper: { className: 'home-page-wrapper teams0-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page teams0' },
  BannerAnim: {
    className: 'banner-anim',
    children: [
      {
        name: 'elem0',
        className: 'teams0-banner-user-elem',
        titleWrapper: {
          className: 'teams0-content-wrapper',
          children: [
            {
              name: 'title~lu81ta6cekm',
              className: 'lu81tfshm8i-editor_css',
              children: (
                <span>
                  <p>领导团队</p>
                </span>
              ),
            },
            {
              name: 'image',
              children:
                'https://bike.powersuit.cn/uploads/6c0xwhs7/admin/images/20240326/202403261405448ebba9732.jpeg',
              className: 'teams0-image',
            },
            {
              name: 'title',
              children: (
                <span>
                  <p>南泽华</p>
                </span>
              ),
              className: 'teams0-h1',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <span>
                    <p>POWERSUIT创始人、CEO</p>
                  </span>
                </span>
              ),
              className: 'teams0-content lu82imsgl4-editor_css',
            },
          ],
        },
      },
    ],
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            <br />
                          </p>
                          <p>品牌简介</p>
                          <p>
                            <br />
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content~lu80qu2pkqq',
        className: 'lu80r1cn2a-editor_css',
        children: (
          <span>
            <p>POWERSUIT为陕西南星泽兰商贸有限公司主打品牌</p>
          </span>
        ),
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>
                              <span>
                                主要围绕电商、家政、外卖点餐等互联网领域希望能够构建多元化的产品，长期愿景是助力企业低成本、高效稳定的落地一个个项目。
                              </span>
                              <br />
                            </p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-content lu7ykywhtdf-editor_css',
      },
      {
        name: 'content2',
        children: (
          <span>
            <span>
              <span>
                <p>POWERSUIT的多元化产品立志成为大家首选的商城系统解决方案。</p>
              </span>
            </span>
          </span>
        ),
        className: 'title-content lu7s9hegsor-editor_css',
      },
    ],
  },
};
export const Pricing00DataSource = {
  wrapper: { className: 'home-page-wrapper pricing0-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page pricing0' },
  imgWrapper: { className: 'pricing0-img-wrapper', md: 12, xs: 24 },
  img: {
    className: 'pricing0-img',
    name: 'image',
    children:
      'https://tea.powersuit.cn/uploads/49mwzvsd/admin/images/20240205/202402051615286aea22682.jpeg',
  },
  childWrapper: {
    className: 'pricing0-text-wrapper',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>POWERSUIT · 茶城商城</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-title',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>中国六大茶类：红茶、绿茶、青茶、黄茶、黑茶、白茶。</p>
                    <p>
                      红茶：祁门红茶，正山小种， 锡兰红茶（产自斯里兰卡）等。
                      功效:暖胃（冬日喝很舒服），提神醒脑，促消化，通便等。
                    </p>
                    <p>
                      绿茶:
                      龙井茶，碧螺春，黄山毛峰，信阳毛尖，安吉白茶，六安瓜片等。
                      功效: 清热去火，提神醒脑，降血脂，血糖，护齿明目等。<br />
                    </p>
                    <p>
                      青茶：铁观音，大红袍，肉桂，武夷岩茶，乌龙茶等。
                      功效：辅助减肥，调节血脂等。
                    </p>
                    <p>
                      黄茶：君山银针等。 功效：辅助减肥，清热去火等。<br />
                    </p>
                    <p>
                      黑茶：普洱茶等。 功效：除烦止渴，解腻清神等。<br />
                    </p>
                    <p>
                      白茶：白毫银针等。功效：促进血糖平衡(有利于糖尿病患者)、明目、保肝护肝。
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-content',
      },
      {
        name: 'pricing',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <h2>品品香｜秦嗏｜斛生记｜中茶</h2>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-pricing lu7zedrm5jc-editor_css',
      },
      {
        name: 'button',
        children: {
          href: 'https://tea.powersuit.cn/',
          type: 'primary',
          children: (
            <span>
              <p>点击访问</p>
            </span>
          ),
          target: '_blank',
        },
      },
    ],
  },
};
export const Pricing01DataSource = {
  wrapper: { className: 'home-page-wrapper pricing0-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page pricing0' },
  imgWrapper: { className: 'pricing0-img-wrapper', md: 12, xs: 24 },
  img: {
    className: 'pricing0-img',
    name: 'image',
    children:
      'https://bike.powersuit.cn/uploads/6c0xwhs7/admin/images/20240103/20240103101706b29d03701.png',
  },
  childWrapper: {
    className: 'pricing0-text-wrapper',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>POWERSUIT · 禧玛诺商城</p>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-title',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>
                      <span>
                        禧玛诺自行车俱乐部始建于2002年5月1日，吸纳广大自行车爱好者，拓展群众性的自行车活动,积极推动全民健身活动为宗旨,提高中国自行车运动的方向.现有俱乐部成员2500多名，来自全国各地。
                      </span>
                      <br />
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      俱乐部成立至今，已组织大型活动数多次。积极争取社会各界的支持,紧紧依靠广大车迷朋友,努力发展自身群众性、专业性的优势；以策划、承办各种层次的竞赛、培训和交流为载体;以拓展技巧性、趣味性、探险性为主的“发烧级”运动为重点;不断扩大和提高群众性自行车运动的规模、水平和影响力。
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-content',
      },
      {
        name: 'pricing',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <h2>山地｜砂砾｜公路｜城市｜旅行</h2>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-pricing lu7zrbjt96-editor_css',
      },
      {
        name: 'button',
        children: {
          href: 'https://bike.powersuit.cn/',
          type: 'primary',
          children: '立即购买',
          target: '_blank',
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>产品简介</p>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>POWERSUIT 主要的软件产品有以下几类</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://cdnwww.likeshop.cn/uploads/images/202111031014335a07d7450.png',
          },
          content: {
            children: (
              <span>
                <p>商户商城系统</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://cdnwww.likeshop.cn/uploads/images/20230301185603f12cc3234.png',
          },
          content: {
            children: (
              <span>
                <p>社区团购系统</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://cdnwww.likeshop.cn/uploads/images/2021102619024570bd41853.png',
          },
          content: {
            children: (
              <span>
                <p>外卖点餐系统</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://cdnwww.likeshop.cn/uploads/images/202304040944009afa16710.png',
          },
          content: {
            children: (
              <span>
                <p>上门家政系统</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://cdnwww.likeshop.cn/uploads/images/2023040711261339f811442.png',
          },
          content: {
            children: (
              <span>
                <p>知识付费系统</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://cdnwww.likeshop.cn/uploads/images/20211103182523b0b4b9420.png',
          },
          content: {
            children: (
              <span>
                <p>回收租赁系统</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://cdnwww.likeshop.cn/uploads/images/20230406153115b2bab8799.png',
          },
          content: {
            children: (
              <span>
                <p>同城跑腿系统</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://cdnwww.likeshop.cn/uploads/images/202307181501273379e4515.png',
          },
          content: {
            children: (
              <span>
                <p>CRM系统</p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>产品服务</p>
                </span>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '一站式业务接入',
            },
            { name: 'content', children: '支付、结算、核算接入产品效率翻四倍' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '一站式事中风险监控',
            },
            {
              name: 'content',
              children: '在所有需求配置环节事前风险控制和质量控制能力',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '一站式数据运营',
            },
            {
              name: 'content',
              children: '沉淀产品接入效率和运营小二工作效率数据',
            },
          ],
        },
      },
    ],
  },
};
export const Content51DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>战略伙伴</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://pp.myapp.com/ma_icon/0/icon_42274589_1711095113/256',
          },
          content: {
            children: (
              <span>
                <p>阿里云</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://pp.myapp.com/ma_icon/0/icon_52532321_1711348682/256',
          },
          content: {
            children: (
              <span>
                <p>腾讯云</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://zhengxin-pub.cdn.bcebos.com/logopic/76e638bf0e56679f5a1f452daf3f6f52_fullsize.jpg',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>华为云</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://pp.myapp.com/ma_icon/0/icon_7643_1711073348/256',
            className: 'lu7ufn2pcm-editor_css',
          },
          content: {
            children: (
              <span>
                <p>
                  <span>快递100</span>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://pp.myapp.com/ma_icon/0/icon_5294_1710896817/256',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>支付宝</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://app-center.cdn.bcebos.com/appcenter/file/upload/a7c39683dd48fe24bc9df0c4ba12d260e5e9df79-72d0-4bed-9f72-fcfff23226d7.png',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>微信</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://pp.myapp.com/ma_icon/0/icon_54359640_1706059762/256',
          },
          content: {
            children: (
              <span>
                <p>宝塔</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://pp.myapp.com/ma_icon/0/icon_11215225_1705455851/256',
          },
          content: {
            children: (
              <span>
                <p>哒哒</p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://bike.powersuit.cn/uploads/6c0xwhs7/admin/images/20240105/20240105085025ed9d30709.jpeg',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>联系我们</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <p>联系地址：陕西省西安市高新区金泰新理城 3 期</p>
          <p>联系邮箱：powersuit@163.com</p>
        </span>
      </span>
    ),
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        Copyright © 2023 陕西南星泽兰商贸有限公司 陕ICP备2023012976号<br />
      </span>
    ),
  },
};
